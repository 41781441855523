@media only screen and (width:768px) {

    /*-------Side Nav------------*/
    .side_nav .tab .icon .svg-inline--fa {
        font-size: 15px;
    }

    .side_nav .tab .icon .tab_secondary_icon {
        font-size: 10px;
    }

    .side_nav .tab span {
        font-size: 12px;
    }

    .data_not_available {
        width: 300% !important;
    }

    .dashboard_form_section .dashboard_form {
        width: 80% !important;
    }

    .lookup_button button:nth-child(1) {
        margin-right: 0;
    }

    .complain_lookups_tab_header h3 {
        display: none;
    }

    .complaint_lookups_tab_main {
        margin-left: 0;
    }

    .lookup_button button {
        margin-left: 5px !important;
        padding: 5px !important;
        font-size: 11px;
    }

    .file_box .profile_pic label {
        font-size: 12px;
    }

    .super_user_access_list_form {
        width: 98% !important;
    }
}

@media only screen and (min-width:768px) and (max-width:1024px) {
    .form_section .form_main {
        width: 45%;
        padding: 4%;
    }

    .dashboard_form_section .dashboard_form {
        width: 85% !important;
    }
    .exportToExcel_modal_wrapper .dashboard_form {
        width: 70% !important;
    }
    /*-----Complaint Detail form----*/
    .complaint_detail_section .dashboard_form {
        width: 90%;
    }

    .super_user_management_form .dashboard_form {
        width: 96% !important;
    }

    /*------Complaint lookups Tab--------*/
    .complaint_lookups_tab_main .lookup_tab {
        padding: 5px 20px;
    }

    .complaint_lookups_tab_main .lookup_tab span {
        font-size: 12px;
    }

    /*-------Dashboard table-------------*/
    .table_main table {
        width: 1200px;
    }

    .data_not_available {
        width: 400%;
    }

    .report_button_group {
        top: 2px;
    }

    .user_profile_section .user_id {
        margin: 30px 12px 12px 12px;
    }

    /********** Homepage all section start **********/

    .main_heading_text h1 {
        font-size: 0.8rem;
    }


    .count_main .count_name_icon .svg-inline--fa {
        height: 0.8rem;
    }

    .track_complaint_main .track_sub {
        padding: 0.5rem;
    }

    .track_complaint_main .track_button_main button {
        font-size: 0.8rem;
        border-radius: 5px;
        text-align: end;
    }


    .about_content p {
        font-size: 0.7rem;
    }

    .about_content button {
        font-size: 1rem;
    }

    .notification_card .notification_options {
        top: 15px;
        right: 3.8%;
    }
    .department_report .report_button_group .button {
        font-size: 11px;
    }

}

@media only screen and (min-width:320px) and (max-width:767px) {

    .form_section .form_main {
        width: 95%;
        padding: 5%;
    }

    .dashboard_form_section .dashboard_form {
        width: 100%;
    }

    /********** Homepage all section start **********/
    .ribbon_main {
        flex-direction: column;
    }

    .header_section button {
        font-size: 0.7rem;
    }


    .navbar_tab {
        width: 100%;
    }


    .counter_main .counter_bg_main {
        width: 100vw;
    }

    .main_heading_text h1 {
        font-size: 0.5rem;
    }

    .count_main .count_name_icon .svg-inline--fa {
        height: 0.5rem;
    }


    .track_complaint_main .track_sub {
        padding: 3px 0;
        text-align: center;
    }


    .track_complaint_main .track_button_main button {
        font-size: 0.6rem;
        border-radius: 5px;
        padding: 2px 5px;
    }

    .about_content {
        text-align: center;
    }

    .about_content .paragraph {
        width: 100%;
    }

    .about_content p {
        font-size: 0.7rem;
        margin-bottom: 1rem;
    }

    .about_content button {
        font-size: 1rem;
    }

    .about_content .underline_thik,
    .about_content .underline_thin {
        margin: auto;
    }

    .about_content .underline_thin {
        margin-bottom: 1rem;
    }

    .admin_content_main h4,
    .admin_content_main p {
        font-size: 0.7rem;
    }

    .admin_card_main .img_box_main {
        padding: 1rem;
    }

    .admin_content_main {
        padding: 1rem 0.5rem;
    }

    .footer_link_main ul {
        padding: 0;
    }

    .footer_link_main ul .footer_link h4 {
        text-align: center;
    }

    .social_media_main .social_media_wrapper .icon_main {
        width: 2.1rem;
        height: 2.1rem;
        margin: 0 0.1rem;
    }

    .footer_copyright .left,
    .footer_copyright .right {
        text-align: center;
    }

    .footer_copyright p {
        font-size: 0.6rem;
        padding: 0.3rem;
    }

    /*********** Homepage all section End ***********/
}

@media only screen and (max-width: 319px) {
    .ribbon_main {
        flex-direction: column;
    }


    .navbar_tab {
        width: 100%;
    }



    .count_main .count_name_icon .svg-inline--fa {
        height: 0.6rem;
    }

}

@media only screen and (min-width:1025px) and (max-width:1200px) {
    .file_box .profile_pic label {
        font-size: 12px;
    }
}