/* Small Mobile */
@media only screen and (max-width: 320px) {

    /************ Homepage Start ************/
    .ribbon_details a h4 {
        font-size: 0.6rem !important;
    }

    .ribbon_main {
        padding: 5px;
    }

    .ribbon_details a h4,
    .ribbon_action_main h4 {
        font-size: 9px !important;
    }

    .ribbon_action_main {
        margin-top: 8px;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        margin-bottom: 0;
    }

    .font_size {
        display: none;
    }

    .header_section img {
        height: 2.5rem;
    }

    .header_section button {
        font-size: 7px;
        padding: 4px;
        font-weight: 700;
    }

    .navbar_main a h3 {
        font-size: 0.6rem !important;
    }

    .counter_main {
        height: 55px;
    }

    .counter_main .counter_bg_main {
        height: 55px;
        background-size: 100% 100%;
    }

    .count_main .count_name_icon h5 {
        font-size: 0.3rem;
    }

    .count_main .count_name_icon h2 {
        font-size: 0.8rem;
    }

    .site_visitor_box {
        width: 9rem;
    }

    .site_visitor_box .site_visitor_details {
        padding: 3px 0;
    }

    .site_visitor_box h2 {
        font-size: 13px;
    }

    .site_visitor_box h3 {
        font-size: 12px;
    }

    .heading_main .map img {
        width: 70%;
        height: 100%;
    }

    .hero img {
        height: 30vh;
    }

    .main_heading_text h1 {
        font-size: 0.5rem;
    }

    .track_complaint_main .track_text_main h3 {
        font-size: 0.6rem !important;
        text-align: center;
    }

    .complaints_counter_section .complaints_counter_card {
        width: 100%;
    }

    .about_content h2 {
        font-size: 2rem !important;
    }

    .admin_main .admin_heading h4 {
        font-size: 0.8rem !important;
    }


    .gallary_section .underline_thik,
    .gallary_section .underline_thin,
    .admin_main .heading_line .underline_thik,
    .admin_main .heading_line .underline_thin,
    .imp_link_heading_line .underline_thik,
    .imp_link_heading_line .underline_thin {
        margin: auto;
    }

    .gallary_main,
    .admin_main .admin_heading h3,
    .imp_link_heading {
        text-align: center;
    }

    .gallary_section ul li h3 {
        font-size: 0.7rem !important
    }

    .footer_link_main ul .footer_link p {
        /* text-align: center; */
        font-size: 0.7rem !important;
    }

    .public_table_section .outer_wrapper .wrapper {
        width: 100%;
    }

    .public_table_section table {
        width: 800px;
    }

    .public_table_section table thead h4 {
        font-size: 11px !important;
    }

    .public_table_section table tbody h5 {
        font-size: 10px !important;
    }

    .public_table_section .table_header h6 {
        font-size: 13px;
    }

    .public_table_section .table_header button {
        font-size: 12px;
    }

    .form_main .track_status {
        height: 90vh;
        overflow-y: auto;
    }

    /************ Homepage End************/
    /************ About us Page Start************/
    .app_flow_main {
        width: 100%;
    }

    .app_flow_main .app_flow_bg {
        height: 55px;
        background-size: 100% 100%;
    }

    .app_flow_main .app_flow_bg .app_flow_row .flow_col {
        padding: 0;
    }

    .app_flow_main .app_flow_bg .app_flow_row .flow_col img {
        height: 1rem;
        margin: 0.2rem auto;
    }

    .app_flow_main .app_flow_bg .app_flow_row .flow_col h5 {
        font-size: 0.3rem;
        font-weight: 700;
        text-transform: capitalize;
        padding: 0 14px;
    }

    .complaints_counter_section .column:nth-child(1),
    .complaints_counter_section .column:nth-child(3) {
        padding-right: 0;
    }

    .complaints_counter_section .column:nth-child(2),
    .complaints_counter_section .column:nth-child(4) {
        padding-left: 0;
    }

    .about_page_content_heading h4 {
        text-align: center;
    }

    .about_page_content_heading_line .underline_thik,
    .about_page_content_heading_line .underline_thin {
        margin: auto;
    }

    .about_pg_img_box img {
        margin-bottom: 0.625rem;
    }

    .about_page_content_main .about_page_content_inner_main .about_page_content_box h3 {
        font-size: 0.6rem !important;
        text-align: center;
    }

    .about_page_content_main .about_page_content_inner_main .about_page_content_box p {
        font-size: 0.6rem !important;
        text-align: justify;
    }

    /************* About us Page End *************/
    /************ Department Page Start ************/

    .department_main .department_table_heading .first_heading h3,
    .department_main .department_table_heading .second_heading h3,
    .department_main .department_table_data .first_data h3,
    .department_main .department_table_data .second_data h3 {
        font-size: 0.6rem !important;
    }

    .department_main .heading_line .underline_thik,
    .department_main .heading_line .underline_thin {
        font-size: 0.5rem !important;
    }

    /************* Department Page End *************/
    /************* FAQ Page Start *************/

    .que_drop_icon,
    .que_mark_icon {
        width: 8%;
        padding: 0;
    }

    .que_ans_text_box .que_main .que h3 {
        font-size: 0.6rem !important;
        margin: 0.625rem 0;
    }

    .que_ans_text_box .ans_text_box p {
        font-size: 0.6rem !important;
    }

    /************* FAQ Page End *************/
    /*============Public modal start============*/
    .public_form_section .form_main {
        width: 95%;
    }

    .public_form_section .raise_complaint_form {
        height: 100vh;
        overflow-y: auto;
    }

    .form_main .form .mobile_verification {
        width: 100%;
    }

    .form .otp_verification_main input {
        width: 14%;
    }

    .complaint_Login_main input {
        width: 85%;
    }

    .track_status {
        width: 100%;
    }

    .status_main_box .status_name h4 {
        font-size: 0.4rem;
        padding: 7px;
    }

    /* Footer CSS */

    .footer_link_main ul .footer_heading img {
        height: 50px;
        margin: 2rem 0;
    }

    .card_main_div .wraper {
        width: 100%;
    }

    .imp_link_main .imp_link_inner_main .card_main_div .imp_link_div {
        justify-content: center;
        flex-direction: column;
        border-radius: 15px;
    }

    .imp_link_main .imp_link_inner_main .card_main_div img {
        width: 30px;
    }

    .imp_link_main .imp_link_inner_main .card_main_div .imp_link_div a {
        font-size: 8px;
    }

    .imp_link_div .title {
        line-height: 10px;
    }

    .imp_link_div .title {
        line-height: 10px;
    }

    /*============Public modal end=============*/
}


/* Mobile */
@media only screen and (min-width:321px)and (max-width: 480px) {

    /************ Homepage Start ************/
    .font_size {
        display: none;
    }

    .header_section img {
        height: 2.5rem;
    }

    .ribbon_action_main {
        margin-top: 8px;
    }

    .navbar_main a h3 {
        font-size: 0.6rem !important;
    }

    .ribbon_details a h4,
    .ribbon_action_main h4 {
        font-size: 10px !important;
    }

    .ribbon_main {
        padding: 5px;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        margin-bottom: 0;
    }

    .counter_main {
        height: 59px;
    }

    .counter_main .counter_bg_main {
        height: 59px;
        background-size: 100% 100%;
    }


    .count_main .count_name_icon h5 {
        font-size: 0.4rem;
    }

    .count_main .count_name_icon h2 {
        font-size: 0.9rem;
    }

    .site_visitor_box {
        width: 9rem;
    }

    .site_visitor_box .site_visitor_details {
        padding: 3px 0;
    }

    .site_visitor_box h2 {
        font-size: 13px;
    }

    .site_visitor_box h3 {
        font-size: 12px;
    }

    .heading_main .map img {
        width: 45%;
        height: 100%;
    }

    .hero img {
        height: 30vh;
    }

    .track_complaint_main .track_text_main h3 {
        font-size: 0.6rem !important;
        text-align: center;
    }

    .complaints_counter_section .complaints_counter_card {
        width: 100%;
    }

    .about_content h2 {
        font-size: 2rem !important;
    }

    .admin_main .admin_heading h4 {
        font-size: 0.8rem !important;
    }

    .gallary_main,
    .admin_main .admin_heading h3,
    .imp_link_heading {
        text-align: center;
    }

    .gallary_section .underline_thik,
    .gallary_section .underline_thin,
    .admin_main .heading_line .underline_thik,
    .admin_main .heading_line .underline_thin,
    .imp_link_heading_line .underline_thik,
    .imp_link_heading_line .underline_thin {
        margin: auto;
    }

    .gallary_section ul li h3 {
        font-size: 0.7rem !important
    }

    .footer_link_main ul .footer_link p {
        /* text-align: center; */
        font-size: 0.7rem !important;
    }

    .public_table_section .outer_wrapper .wrapper {
        width: 100%;
        max-height: 100vh;
    }

    .public_table_section table {
        width: 800px;
    }

    .public_table_section table thead h4 {
        font-size: 11px !important;
    }

    .public_table_section table tbody h5 {
        font-size: 10px !important;
    }

    .public_table_section .table_header h6 {
        font-size: 13px;
    }

    .public_table_section .table_header button {
        font-size: 12px;
    }

    .form_main .track_status {
        height: 90vh;
        overflow-y: auto;
    }

    /************ Homepage End************/

    /************ About us Page Start************/

    .app_flow_main {
        width: 100%;
    }

    .app_flow_main .app_flow_bg {
        height: 76px;
        background-size: 100% 100%;
    }

    .app_flow_main .app_flow_bg .app_flow_row .flow_col {
        padding: 0;
    }

    .app_flow_main .app_flow_bg .app_flow_row .flow_col img {
        height: 1.5rem;
        margin: 0.2rem auto;
    }

    .app_flow_main .app_flow_bg .app_flow_row .flow_col h5 {
        font-size: 0.5rem;
        font-weight: 700;
        text-transform: capitalize;
        padding: 0 12px;
    }

    .about_page_content_heading h4 {
        text-align: center;
    }

    .about_page_content_heading_line .underline_thik,
    .about_page_content_heading_line .underline_thin {
        margin: auto;
    }

    .about_pg_img_box img {
        margin-bottom: 0.625rem;
    }

    .about_page_content_main .about_page_content_inner_main .about_page_content_box h3 {
        font-size: 0.6rem !important;
        text-align: center;
    }

    .about_page_content_main .about_page_content_inner_main .about_page_content_box p {
        font-size: 0.6rem !important;
        text-align: justify;
    }

    /************* About us Page End*************/

    /************ Department Page Start ************/

    .department_main .department_table_heading .first_heading h3,
    .department_main .department_table_heading .second_heading h3,
    .department_main .department_table_data .first_data h3,
    .department_main .department_table_data .second_data h3 {
        font-size: 0.6rem !important;
    }

    .department_main .heading_line .underline_thik,
    .department_main .heading_line .underline_thin {
        margin: auto;
    }

    /************* Department Page End *************/

    /************* FAQ Page Start *************/

    .que_drop_icon,
    .que_mark_icon {
        width: 8%;
        padding: 0;
    }

    .que_ans_text_box .que_main .que h3 {
        font-size: 0.6rem !important;
        margin: 0.625rem 0;
    }

    .que_ans_text_box .que_main .que_drop_icon img {
        height: 6px;
    }

    .que_ans_text_box .que_main .que_mark_icon .svg-inline--fa {
        height: 12px;
    }

    .que_ans_text_box .ans_text_box p {
        font-size: 0.6rem !important;
    }


    /************* FAQ Page End *************/
    /*============Public modal start============*/
    .public_form_section .form_main {
        width: 95%;
    }

    .public_form_section .raise_complaint_form {
        height: 100vh;
        overflow-y: auto;
    }

    .form_main .form .mobile_verification {
        width: 100%;
    }

    .form .otp_verification_main input {
        width: 14%;
    }

    .complaint_Login_main input {
        width: 85%;
    }

    .track_status {
        width: 100%;
    }

    .status_main_box .status_name h4 {
        font-size: 0.5rem;
        padding: 7px;
    }

    /*============Public modal end=============*/

    /* Footer CSS */

    .footer_link_main ul .footer_heading img {
        height: 50px;
        margin: 1rem 0;
    }

    .card_main_div .wraper {
        width: 100%;
    }

    .imp_link_main .imp_link_inner_main .card_main_div .imp_link_div {
        justify-content: center;
        flex-direction: column;
        border-radius: 15px;
    }

    .imp_link_main .imp_link_inner_main .card_main_div img {
        width: 30px;
    }

    .imp_link_main .imp_link_inner_main .card_main_div .imp_link_div a {
        font-size: 9px;
    }

    .imp_link_div .title {
        line-height: 10px;
    }
}

/* Tablet */
@media only screen and (width: 768px) {

    /************ Homepage Start ************/
    .navbar_main a h3 {
        font-size: 0.9rem !important;
    }

    .counter_main {
        height: 85px;
    }

    .ribbon_action_main {
        margin-top: 0px;
    }

    .ribbon_main {
        padding: 5px !important;
    }

    .counter_main .counter_bg_main {
        height: 85px;
        background-size: 100% 100%;
    }


    .count_main .count_name_icon h5 {
        font-size: 0.6rem;
    }

    .count_main .count_number h2 {
        font-size: 0.8rem;
        margin-left: 0.2rem;
    }

    .complaints_counter_section .complaints_counter_card {
        width: 100%;
    }

    .heading_main .map img {
        width: 43%;
        height: 100%;
    }

    .about_content .underline_thik,
    .about_content .underline_thin {
        width: 100%;
    }

    .public_table_section .outer_wrapper .wrapper {
        width: 95%;
        height: 80vh;
        overflow: auto;
    }

    .public_table_section table {
        width: 1000px;
    }

    .public_table_section table thead h4 {
        font-size: 13px !important;
    }

    .public_table_section table tbody h5 {
        font-size: 12px !important;
    }

    .public_table_section .table_header h6 {
        font-size: 14px;
    }

    .public_table_section .table_header button {
        font-size: 13px;
    }

    .track_complaint_section .form_main {
        width: 90%;
    }

    /************ Homepage End************/

    /************ About us Page Start************/
    .app_flow_main {
        width: 100%;
    }

    .app_flow_main .app_flow_bg {
        height: 85px;
        background-size: 100% 100%;
    }

    .app_flow_main .app_flow_bg .app_flow_row .flow_col {
        padding: 0;
    }

    .app_flow_main .app_flow_bg .app_flow_row .flow_col img {
        height: 1.8rem;
        margin: 0.2rem auto;
    }

    .app_flow_main .app_flow_bg .app_flow_row .flow_col h5 {
        font-size: 0.8rem;
        padding: 0 20px;
    }

    .card_main_div .wraper {
        width: 90%;
    }

    /************* About us Page End*************/

}

@media only screen and (min-width: 481px)and (max-width: 767px) {

    .header_section img {
        height: 2.7rem;
    }

    .heading_main .map img {
        width: 55%;
        height: 100%;
    }

    .ribbon_action_main {
        margin-top: 8px;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        margin-bottom: 0;
    }

    .gallary_main {
        text-align: center;
    }

    .gallary_section .underline_thik,
    .gallary_section .underline_thin,
    .admin_main .heading_line .underline_thik,
    .admin_main .heading_line .underline_thin,
    .imp_link_heading_line .underline_thik,
    .imp_link_heading_line .underline_thin {
        margin: auto;
    }

    .gallary_main,
    .admin_main .admin_heading h3,
    .imp_link_heading {
        text-align: center;
    }

    .gallary_section ul li h3 {
        font-size: 0.7rem !important
    }

    .footer_link_main ul .footer_link p {
        font-size: 0.7rem !important;
    }

    .public_table_section .outer_wrapper .wrapper {
        width: 100%;
    }

    .public_table_section table {
        width: 900px;
    }

    .public_table_section table thead h4 {
        font-size: 12px !important;
    }

    .public_table_section table tbody h5 {
        font-size: 11px !important;
    }

    .public_table_section .table_header h6 {
        font-size: 13px;
    }

    .public_table_section .table_header button {
        font-size: 12px;
    }

    .form_main .track_status {
        height: 90vh;
        overflow-y: auto;
    }

    .app_flow_main {
        width: 100%;
    }

    .app_flow_main .app_flow_bg {
        height: 66px;
        background-size: 100%;
    }

    .app_flow_main .app_flow_bg .app_flow_row .flow_col {
        padding: 0;
    }

    .app_flow_main .app_flow_bg .app_flow_row .flow_col img {
        height: 1.5rem;
        margin: 0.2rem auto;
    }

    .app_flow_main .app_flow_bg .app_flow_row .flow_col h5 {
        font-size: 0.5rem;
        padding: 0 12px;
    }

    .complaints_counter_section .complaints_counter_card {
        width: 100%;
    }

    .about_page_content_heading h4 {
        text-align: center;
    }

    .about_page_content_heading_line .underline_thik,
    .about_page_content_heading_line .underline_thin {
        margin: auto;
    }

    .about_pg_img_box img {
        margin-bottom: 0.625rem;
    }

    .about_page_content_main .about_page_content_inner_main .about_page_content_box h3 {
        font-size: 0.6rem !important;
        text-align: center;
    }

    .about_page_content_main .about_page_content_inner_main .about_page_content_box p {
        font-size: 0.6rem !important;
        text-align: justify;
    }

    /************* FAQ Page Start *************/

    .que_drop_icon,
    .que_mark_icon {
        width: 8%;
        padding: 0;
    }

    /************* FAQ Page End *************/

    .department_main .department_table_heading .first_heading h3,
    .department_main .department_table_heading .second_heading h3,
    .department_main .department_table_data .first_data h3,
    .department_main .department_table_data .second_data h3 {
        font-size: 0.8rem !important;
    }


    /*============Public modal start============*/
    .public_form_section .form_main {
        width: 95%;
    }

    .public_form_section .raise_complaint_form {
        height: 100vh;
        overflow-y: auto;
    }

    .form_main .form .mobile_verification {
        width: 100%;
    }

    .form .otp_verification_main input {
        width: 14%;
    }

    .complaint_Login_main input {
        width: 75%;
    }

    /*============Public modal end=============*/

}


/* Desktop */
@media only screen and (min-width: 769px) and (max-width: 1024px) {

    /************ Homepage Start ************/
    .navbar_main a h3 {
        font-size: 0.9rem !important;
    }

    .counter_main {
        height: 93px;
    }

    .ribbon_action_main {
        margin-top: 0px;
    }

    .count_main .count_name_icon h5 {
        font-size: 0.6rem;
    }

    .count_main .count_number h2 {
        font-size: 0.7rem;
        margin-left: 0.2rem;
    }

    .heading_main .map img {
        width: 55%;
        height: 100%;
    }

    .public_table_section .outer_wrapper .wrapper {
        width: 95%;
    }

    .public_table_section table {
        width: 1200px;
    }

    .public_table_section table thead h4 {
        font-size: 13px !important;
    }

    .public_table_section table tbody h5 {
        font-size: 12px !important;
    }

    .public_table_section .table_header h6 {
        font-size: 15px;
    }

    .public_table_section .table_header button {
        font-size: 14px;
    }

    /************ Homepage End************/

    /************ About us Page Start************/
    .app_flow_main {
        width: 100%;
    }

    .app_flow_main .app_flow_bg {
        height: 107px;
        background-size: 100% 100%;
    }

    .app_flow_main .app_flow_bg .app_flow_row .flow_col {
        padding: 18px 0;
    }

    .app_flow_main .app_flow_bg .app_flow_row .flow_col img {
        height: 1.8rem;
        margin: 0.2rem auto;
    }

    .app_flow_main .app_flow_bg .app_flow_row .flow_col h5 {
        font-size: 0.8rem;
        padding: 0 20px;
    }


    /************* About us Page End*************/
    /*======Public Modal start=========*/
    .public_form_section .form_main {
        width: 85%;
    }

    /*======Public Modal end=========*/

}

/* Huge */
@media only screen and (min-width: 1025px) and (max-width: 1600px) {

    /************ Homepage Start ************/
    .counter_main {
        height: 130px;
    }


    /************ Homepage End************/

    /************ About us Page Start************/
    .app_flow_main .app_flow_bg {
        height: 160px;
        background-size: 100% 100%;
    }

    .app_flow_main .app_flow_bg .app_flow_row .flow_col {
        padding: 30px 0;
    }


    /************* About us Page End*************/

}