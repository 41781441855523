@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Noto Sans', sans-serif;
}

:root {
    --ribbon_1st_theme: #60676E;
    --border_radius: 0.25rem;
    /* --box_shadow: 0 0.05rem 0.5rem #cecece; */
    --input_box_shadow: 0px 0.05rem 0.375rem #cecece;
    /* --primary_color: #808080; */
    --primary_font_size: 15px;
    --secondary_font_size: 14px;
    --heading_font_size: 16px;
    --primary_color: #8887C9;
    --margin: 1.875rem;
    --main_div_padding: 0.3rem 1.5rem;
    --theme_blue: #394D61;
    --box_shadow: 5px 5px 5px #e4e4e4;
    --button_gradient: linear-gradient(to right, #A09FDA, #7D7CB7);
}

a {
    text-decoration: none;
}

h3,
h4,
h5,
h6,
p {
    margin-bottom: 0;
}

.form-control:focus,
.form-select:focus {
    box-shadow: none;
}

input,
select {
    height: 35px;
    font-size: 14px !important;
}

/* input:disabled {
    background-color: rgb(222, 213, 213) !important;
} */
/* textarea:disabled {
    background-color: transparent !important;
} */

textarea {
    font-size: 14px !important;
    color: var(--primary_color) !important;
}

.form-select {
    /* border: 1px solid var(--primary_color); */
    color: var(--primary_color);
    font-weight: 500;
    /* background-color: transparent; */
}

input::placeholder,
textarea::placeholder {
    font-size: 14px;
    color: var(--primary_color) !important;
    font-weight: 500;
}

input[type="file"] {
    /* border: 1px solid var(--primary_color); */
    /* background-color: transparent; */
    color: var(--primary_color);
}

input[type="date"] {
    border: 1px solid var(--primary_color);
    background-color: transparent;
}

input[type="date"]:focus {
    background-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.data_not_available {
    width: 440%;
    height: 30vh;
    display: grid;
    place-items: center;
}

.data_not_available h5 {
    font-weight: bold;
}

/*------------CSS for Swal Modal Box start--------*/
.swal-overlay--show-modal .swal-modal {
    border-radius: 20px;
}

.swal-footer {
    text-align: center;
    padding: 7px 16px;
    background-color: var(--primary_color);
}

.swal-button {
    padding: 5px 15px;
}

.swal-button-container button {
    background-color: #9f9ee5;
    color: #fff;
}

.swal-button-container .swal-button--cancel:hover {
    background-color: #9f9ee5;
}

.swal-icon {
    margin: 15px auto;
}

.swal-icon:first-child {
    margin-top: 10px;
}

/*------------CSS for Swal Modal Box end--------*/

/*============CSS for Loader Start=============*/
.Loader_main {
    position: absolute;
    top: 0;
    width: 99%;
    height: 100%;
    /* background-color: rgba(255, 255, 255, 0.637); */
    display: grid;
    place-items: center;
}

.Loader_main .loader {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: var(--input_box_shadow);
    display: grid;
    place-items: center;
    /* border: 3px solid var(--primary_color);
    border-top-color: #EE741D;
    border-right-color: #1CE005;
    border-bottom-color: #FABE0B;
    animation: border_anim 0.6s linear infinite; */
}

@keyframes border_anim {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.Loader_main .loader img {
    width: 100%;
    height: 100%;
    animation: loader_anim 1s infinite alternate-reverse;
}

@keyframes loader_anim {
    0% {
        transform: scale(0.5);
    }

    100% {
        transform: scale(1);
    }

}

/*============CSS for Loader End===============*/
/*============CSS for Dashboard Start==============*/
.dashboard_section {
    width: 100vw;
    height: 100vh;
    display: flex;
    /* overflow: hidden; */
}

.side_nav {
    width: 5%;
    height: 100vh;
    background-color: var(--primary_color);
    overflow: hidden;
    transition: 0.5s;
    overflow-y: auto;
    z-index: 1;
}

.side_nav::-webkit-scrollbar {
    width: 0;
}

.panel_section {
    height: 100vh;
    transition: .5s;
}

.routes_view {
    position: relative;
}

.panel_section .top_header {
    width: 100%;
    height: 7vh;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 0 15px;
    box-shadow: var(--box_shadow);
}

.toggler_icon img {
    cursor: pointer;
}

/*----css for top header-----*/
.top_header .header_action_section {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.font_multiplier span {
    color: var(--primary_color);
    font-size: var(--heading_font_size);
    font-weight: 700;
    cursor: pointer;
}

.font_multiplier span:nth-child(2) {
    margin: 0 8px;
}

.dark_light_mode {
    display: flex;
    padding: 4px;
    background-color: #F4F4FC;
    border-radius: 8px;
    margin: 0 25px;
}

.dark_light_mode .light_mode,
.dark_light_mode .dark_mode {
    padding: 1px 8px;
    background-color: #fff;
}

.dark_light_mode .light_mode {
    margin-right: 5px;
}

.dark_light_mode .light_mode .svg-inline--fa,
.dark_light_mode .dark_mode .svg-inline--fa {
    color: var(--primary_color);
}

.top_header .header_action_section .langauge_main {
    height: 28px;
}

.top_header .header_action_section .goog-te-gadget .goog-te-combo {
    padding: 0 5px;
    width: 150px !important;
    height: 24px !important;
    margin: 0 25px 0 0;
    background-color: transparent;
    border: 1px solid var(--primary_color);
    color: var(--primary_color);
    font-size: 12px !important;
    border-radius: var(--border_radius);
}

.top_header .header_action_section .goog-te-gadget .goog-te-combo:focus {
    outline: none;
}

/*------CSS for Dashboard Header Start------------*/

.user_profile_action_section .user_detail .user_name {
    display: flex;
    align-items: center;
}

.user_profile_action_section {
    display: flex;
    align-items: center;
}

.user_profile_action_section .user_icon {
    display: grid;
    place-items: center;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin-right: 15px;
    border: 1px solid var(--primary_color);
    overflow: hidden;
}

.user_profile_action_section .user_icon img {
    width: 100%;
    height: 100%;
}

.user_profile_action_section .user_icon .svg-inline--fa {
    margin-left: 0;
    color: var(--primary_color);
}

.user_profile_action_section h5 {
    font-size: 14px;
    color: var(--primary_color);
    font-weight: 600;
}

.user_profile_action_section h6 {
    font-size: 12px;
}

.user_profile_action_section .svg-inline--fa {
    margin-left: 5px;
    cursor: pointer;
    color: var(--primary_color);
}

.user_profile_action_section .user_name {
    /* margin-bottom: 5px; */
    color: #5F5553;
}

.user_profile_action_section .user_type {
    position: relative;
    padding-left: 10px;
}

.user_profile_action_section .user_type h6 {
    color: var(--primary_color);
}

.user_profile_action_section .user_type::before {
    position: absolute;
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: var(--primary_color);
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);

}

/*---------css for header end---------*/

/*---------css for Sign in page start-----*/
.form_section {
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(to bottom, #FFFAF2, #CCE6FF);
    display: grid;
    place-items: center;
}

.form_section .form_main {
    width: 30%;
    margin: auto;
    padding: 2%;
    border-radius: var(--border_radius);
    background-image: linear-gradient(to bottom, #FDF9F3, #D0E7FD);
    box-shadow: var(--box_shadow);
    text-align: center;
}

.form_section .centered_icon .svg-inline--fa {
    color: #1B99E5;
    font-size: 3rem;
}

.form_section .input_field {
    display: flex;
    align-items: center;
    padding: 0.188rem 0;
    background-color: #fff;
    border-radius: var(--border_radius);
    box-shadow: var(--input_box_shadow);
    margin-top: var(--margin);
    position: relative;
}

.form_section .input_field input {
    border: none;
    padding-left: 20px;
}

.input_field .show_pass_icon {
    position: absolute;
    top: 2px;
    right: 3px;
}

.input_field .show_pass_icon .svg-inline--fa {
    font-size: 16px;
}

.form_section .input_field input:focus {
    box-shadow: none;
}

.form_section .input_field .input_icon {
    padding: 0 0.75rem;
    border-right: 0.063rem solid var(--primary_color);
}

.show_pass_icon {
    color: #808080;
    padding: 0 0.75rem;
    font-size: 1.25rem;
    border-right: none
}

.show_pass_icon img {
    width: 1.3rem;
}

.form_section .input_field .input_icon .svg-inline--fa {
    color: #808080;
    font-size: 1.25rem;
}

.form_section .forget_password {
    text-align: right;
    margin-top: var(--margin);
}

.form_section .forget_password span {
    cursor: pointer;
    color: #1B99E5;
    font-size: 14px;
    font-weight: 600;
    margin-left: 1rem;
}

.form_section button {
    margin-top: var(--margin);
    padding: 0.313rem 1.875rem;
    border-radius: var(--border_radius);
    border: none;
    background-color: #1B99E5;
    color: #fff;
    font-weight: 600;
}

.srv-validation-message {
    color: rgb(231, 158, 63);
    margin-top: 0.5rem;
    text-align: left;
    font-size: 14px;
}

/*---------css for sign in page end-------*/
/*---------css for Side nav---------*/
.side_nav .logo {
    height: 25%;
    text-align: center;
}

.side_nav .tab {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    /* margin-bottom: 15px; */
    /* margin-top: 5px; */
    height: 50px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.side_nav .tab span {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
}

.profile_nav .tab span {
    color: var(--primary_color);
    font-size: 14px;
    font-weight: 600;
}

.profile_nav .active .tab span {
    color: #fff;
}

.side_nav .active .tab {
    background-color: #fff;
}

.profile_nav .active .tab {
    background-color: var(--primary_color);
}

.side_nav .active .tab span {
    color: var(--primary_color);
}

.side_nav .active .tab .svg-inline--fa,
.profile_nav .active .tab .icon .tab_secondary_icon {
    color: var(--primary_color) !important;
}

.profile_nav .active .tab .svg-inline--fa {
    color: #fff;
}

.side_nav .tab:hover {
    background-color: #fff;
}

.side_nav .tab:hover span {
    color: var(--primary_color);
}

.side_nav .tab:hover .svg-inline--fa {
    color: var(--primary_color) !important;
}

.side_nav .tab .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* margin: 0 32px 0 18px; */
}

.side_nav .user_management_tab .icon {
    margin: 0 21px 0 18px;
}

.side_nav .tab .icon .svg-inline--fa {
    font-size: 24px;
    color: #fff;
}

.side_nav .tab .icon .tab_secondary_icon {
    position: absolute;
    top: -7px;
    right: -11px;
    font-size: 14px;
}

.side_nav .tab .title {
    color: var(--primary_color);
    font-weight: 600;
}

.side_nav .tab .count_div {
    background-color: var(--primary_color);
    border-radius: 5px;
    position: absolute;
    top: -7px;
    right: -11px;
    margin-left: 10px;
    z-index: 1;
    animation: bgChange 2s infinite;
    padding: 2px 5px;
}

@keyframes bgChange {
    0% {
        background-color: var(--primary_color);
    }

    50% {
        background-color: yellow;
    }
}

.side_nav .tab .count_div h6 {
    color: #fff;
    animation: textChange 2s infinite;
    font-size: 0.7rem;
    font-size: 9px;
}

@keyframes textChange {
    0% {
        color: yellow;
    }

    50% {
        color: #000;
    }
}

/*-----css for panel section----------*/
.user_profile_section,
.dashboard_form_section,
.raise_complaint_section {
    width: 100%;
    height: 93vh;
    display: grid;
    place-items: center;
}

.user_profile_section .user_profile {
    border-radius: var(--border_radius);
    box-shadow: var(--box_shadow);
    padding: 5% 2% 2% 2%;
    position: relative;
}
.user_profile_section .list {
    width: 100%;
    max-height: 100px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.user_profile_section .user_id {
    text-align: center;
    margin: 12px 0;
}

.user_profile_section .user_id h4 {
    font-weight: 600;
}

.user_profile_section .profile_input_row {
    margin: 15px 0;
}

.user_profile_section input {
    margin-top: 5px;
}

.user_profile .icon_main {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: #fff; */
    display: grid;
    place-items: center;
    /* padding: 8px; */
    overflow: hidden;
    /* box-shadow: var(--box_shadow); */
}

.user_profile .icon_main img {
    width: 100%;
    height: 100%;
}

.icon_main .edit_profile {
    position: absolute;
    bottom: 10%;
    right: 15%;
}

.icon_main .edit_profile .svg-inline--fa {
    cursor: pointer;
    color: #2BBE6D;
}

.user_profile .upload_profile {
    position: absolute;
    top: 2%;
    right: 5%;
}

.user_profile .upload_profile .button {
    margin-top: 5px;
}

.button_group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

/* .button_group .button {
    width: 100px;
} */

/* .button_group button:nth-child(1) {
    margin-right: 20px;
} */

.button {
    border-radius: var(--border_radius);
    border: none;
    background-image: var(--button_gradient);
    color: #fff;
    padding: 5px 15px;
    font-size: 14px;
    font-weight: 600;
}

.button .svg-inline--fa {
    color: #20d13e;
    margin-left: 5px;
}

/*---------css for profile nav-------*/
.routes_view .profile_nav {
    position: absolute;
    top: 0;
    right: 0;
    /* border: 6px solid #F8F8F8; */
    background-color: #fff;
    z-index: 2;
}

.routes_view .profile_nav .tab {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
}

.profile_nav .tab .title {
    margin-left: 10px;
}

.profile_nav .tab .svg-inline--fa {
    color: var(--primary_color);
}

/*---------css for panel------*/
.panel_section .panel {
    width: 100%;
    height: 93vh;
    padding: 10px;
    position: relative;
}

.panel .search_header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

/* .panel .search_header h3 {
    color: var(--primary_color) !important;
} */

.panel .search_input {
    margin: 0 20px;
    position: relative;
}

.panel .search_input input {
    border: 1px solid var(--primary_color);
    /* box-shadow: var(--input_box_shadow); */
    background-color: transparent;
    /* color: var(--primary_color); */
    padding-right: 30px;
}

.panel .search_input .svg-inline--fa {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    color: var(--primary_color);
    font-size: 18px;
}

.statuswise_complaints_header {
    position: relative;
}

.statuswise_complaints_header .button_group {
    position: absolute;
    right: 0;
}

.statuswise_complaints_header h3 {
    text-transform: capitalize;
}

/*----css for table----------*/
.table_main {
    width: 100%;
    /* height: 83vh; */
    height: 79vh;
    overflow: auto;
}

.table_main::-webkit-scrollbar {
    width: 5px;
}

.table_main::-webkit-scrollbar-track {
    /* background-image: linear-gradient(to bottom, #CFCDF5, #A4A3D9); */
    background-color: transparent;
}

.table_main::-webkit-scrollbar-thumb {
    background-image: linear-gradient(to right, #CFCDF5, #A4A3D9);
}

.lookup_table_main {
    height: 68vh;
}

.table_main table,
.public_table_main table {
    border-collapse: separate;
    border-spacing: 0 3px;
    width: 100%;
    overflow: auto;
}

.table_main::-webkit-scrollbar:horizontal {
    height: 5px !important;
}

.lookup_tabel_main table {
    min-width: max-content;
}

.table_main table thead,
.public_table_main table thead {
    position: sticky;
    top: 0;
    z-index: 1;
}

.table_main table thead tr {
    background-image: linear-gradient(#CFCDF5, #A4A3D9);
    text-align: center;
}

.public_table_main table thead tr,
.public_table_main table tbody tr {
    text-align: center;
}

.table_main table thead tr th,
.table_main table tbody tr td,
.public_table_main table thead tr th,
.public_table_main table tbody tr td {
    padding: 5px 0;
}

.table_main table thead h4,
.table_main table tbody h5,
.public_table_main table thead h4,
.public_table_main table tbody h5 {
    text-transform: capitalize;
}

.header_text {
    /* border-right: 1px solid #fff; */
    color: #fff;
}

/* .table_main table thead th:last-child h4,
.table_main table tbody td:last-child h5 {
    border-right: none;
} */

.table_main table tbody tr {
    text-align: center;
    /* box-shadow: var(--input_box_shadow); */
    background-color: #fff;
}

.table_main table thead h4 {
    padding: 0 5px;
}

.table_main table tbody h5 {
    /* border-right: 1px solid var(--primary_color); */
    padding: 0 5px;
}

.table_main table tbody tr .svg-inline--fa {
    color: var(--primary_color);
    cursor: pointer;
}

.empty_notification {
    height: 50vh;
    display: grid;
    place-items: center;
}

/*-------Department form--------*/
.dashboard_form_section .dashboard_form {
    width: 40%;
    border-radius: var(--border_radius);
    /* box-shadow: var(--box_shadow); */
    border: 1px solid var(--primary_color);
    padding: 2% 5%;
}

.raise_complaint_section .dashboard_form {
    border: 2px solid var(--primary_color);
}

.dashboard_form::-webkit-scrollbar {
    width: 2px;
}

.dashboard_form::-webkit-scrollbar-thumb {
    background: var(--primary_color);
}

.dashboard_form_section .user_management_form {
    width: 60%;
}

.super_user_management_form .dashboard_form {
    width: 80%;
    max-height: 90%;
    overflow-y: scroll;
}

.super_user_management_form .added_user_list {
    margin-bottom: 25px;
}

.super_user_management_form .added_user_list .last_child {
    position: relative;
}

.super_user_management_form .added_user_list .last_child .delete_icon {
    position: absolute;
    right: -5%;
    top: 5px;
    color: red;
    cursor: pointer;
}

.dashboard_form_section .user_management_form .file_box {
    position: relative;
}

.file_box .profile_pic {
    display: flex;
    align-items: center;
    width: 100%;
    height: 35px;
    /* padding: 0.375rem 0.75rem; */
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: #fff;
}

.file_box .profile_pic .choose_file {
    width: 30%;
    height: 100%;
    display: grid;
    place-items: center;
    background-color: #efefef;
    border-right: 1px solid #ced4da;
}

.file_box .profile_pic .file_label {
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    overflow: hidden;
}

.file_box .profile_pic label {
    color: var(--primary_color);
    font-size: 14px;
    font-weight: 500;
}

.dashboard_form_section .user_management_form .file_box .attachment_icon,
.super_user_management_form .file_box .attachment_icon {
    position: absolute;
    top: 0;
    right: -7%;
    border: 1px solid var(--primary_color);
    border-radius: 0.25rem;
    background-color: var(--primary_color);
    cursor: pointer;
}

.dashboard_form_section .user_management_form .file_box .attachment_icon .svg-inline--fa,
.super_user_management_form .file_box .attachment_icon .svg-inline--fa {
    color: #fff;
    padding: 3px;
    font-size: 1.5rem;
}

.dashboard_form .from_title {
    text-align: center;
    margin-bottom: var(--margin);
}

.dashboard_form .from_title h3 {
    font-weight: 600;
    /* color: var(--primary_color) !important; */
}

.dashboard_form .input_field {
    margin-bottom: var(--margin);
    position: relative;
}

.dashboard_form .input_field h5 {
    margin-bottom: 5px;
}

.dashboard_form .input,
.dashboard_form textarea {
    border-radius: var(--border_radius);
    color: var(--primary_color);
    /* border: 1px solid var(--primary_color); */
    /* background-color: transparent; */
}

.dashboard_form textarea:focus {
    background-color: transparent;
}
.complaint_info_form .complaint_detail_textarea {
    min-height: 150px;
}
.dashboard_form .button_group {
    justify-content: flex-start;
}

.user_management_form .button_group,
.super_user_management_form .button_group {
    justify-content: center;
}

/*-------css for complaint lookups tab-------*/
.complain_lookups_tab_header {
    display: flex;
    align-items: center;
}

.complaint_lookups_tab_main {
    display: flex;
    margin-left: 20px;
}

.lookups_search_header {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.report_header {
    position: relative;
}

.report_header .row {
    width: 100%;
}

.department_report {
    position: relative;
    height: 100vh;
    overflow: auto;
}

.statuswise_complaint_list_modal {
    width: 99%;
    height: 85vh;
    margin: 30px 0 0 0;
    position: relative;
    /* position: absolute; */
    /* top: 0; */
}

.statusWise_commplaint_table {
    height: 72vh !important;
}

/* .department_report .input_field {
    margin: 0 10px;
} */
.modal_loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: grid;
    place-items: center;
    z-index: 3;
}

.report_button_group {
    position: absolute;
    top: 0;
    right: 0;
}

.report_button_group .button {
    margin: 0 7px 0 0 !important;
    padding: 5px 7px;
}

.lookups_search_header .search_input {
    margin: 0;
}

.lookups_search_header .date_filter {
    display: flex;
    align-items: center;
    margin: 0 8px;
}

.lookups_search_header .date_filter:nth-child(2) {
    margin: 0 15px;
}

.lookups_search_header .date_filter input {
    margin-left: 10px;
}

.lookups_search_header .button {
    margin-left: 20px;
}

.complaint_lookups_tab_main .lookup_tab {
    display: grid;
    place-items: center;
    padding: 5px 40px;
    background-image: linear-gradient(to right, #A09FDA, #7D7CB7);
    color: #fff;
    position: relative;
}

.complaint_lookups_tab_main .lookup_tab span {
    font-size: 13px;
    font-weight: 600;
}

.complaint_lookups_tab_main .lookup_tab:nth-child(6) {
    border-right: none;
}

.complaint_lookups_tab_main .active .lookup_tab::before {
    position: absolute;
    content: "";
    right: 0%;
    width: 25px;
    height: 100%;
    background-color: #fff;
    border: none;
    clip-path: polygon(100% 0, 39% 47%, 100% 100%);
}

.complaint_detail_section {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    z-index: 2;
}

.complaint_detail_section .dashboard_form {
    width: 80%;
    /* min-height: 70%; */
    max-height: 98%;
    overflow-y: auto;
    padding: 1% 2%;
    border-radius: var(--border_radius);
    border: 2px solid var(--primary_color);
}

.add_document_button {
    position: relative;
}

.add_document_button button {
    position: absolute;
    bottom: 2px;
}

.uploaded_document_list {
    margin-bottom: 5px;
}

.uploaded_document_list .preview_icon .svg-inline--fa {
    color: var(--primary_color);
    cursor: pointer;
}

.uploaded_document_list .delete_icon .svg-inline--fa {
    color: rgb(248, 93, 93);
    cursor: pointer;
}

.dashboard_form .attachement {
    display: flex;
    align-items: center;
}

.attachment_wraper {
    display: flex;
    align-items: center;
}

.dashboard_form .attachement button {
    padding: 1px 5px;
    border-radius: var(--border_radius);
    border: 1px solid var(--primary_color);
    background-color: transparent;
    color: var(--primary_color);
    margin-left: 5px;
}

.complaint_detail_section .dashboard_form .button_group {
    justify-content: flex-end;
    gap: 20px;
}

/* .complaint_detail_section .dashboard_form .button_group button:nth-child(2) {
    margin-right: 20px;
} */

.complaint_detail_section .assign_section input {
    margin-top: 5px;
}

.complaint_detail_section .form_tabs,
.raise_complaint_section .form_tabs {
    display: flex;
    align-items: center;
    position: relative;
}

.complaint_detail_section .form_tabs .tab,
.raise_complaint_section .form_tabs .tab {
    background-image: var(--button_gradient);
    padding: 5px 25px;
    border-radius: var(--border_radius);
    position: relative;
    cursor: pointer;
    margin-right: 15px;
}

.form_tabs .close_complaint_model {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

.complaint_log_section {
    margin-bottom: 15px;
}

.complaint_log_section h5 {
    font-size: 15px;
    color: var(--primary_color);
    font-weight: bold;
}

.complaint_log_section .complaint_log_main {
    display: flex;
    border-radius: var(--border_radius);
    margin-top: 10px;
}

.complaint_log_section .complaint_log_main .log_list {
    width: 30%;
    padding: 5px;
}

.complaint_log_section .complaint_log_detail {
    padding: 5px;
}


.complaint_log_section .complaint_log_main .log_list span {
    font-size: 13px;
    cursor: pointer;
    font-weight: 600;
}

.complaint_log_section .complaint_log_main .log_list span:hover {
    border-bottom: 1px dotted var(--primary_color);
}

.complaint_log_section .complaint_log_main .log_active {
    color: rgb(231, 158, 63) !important;
}

.complaint_log_section span:nth-child(1) {
    margin-right: 8px;
}

.complaint_log_section span:nth-child(2) {
    margin-left: 8px;
}

.complaint_log_detail {
    width: 100%;
    background-color: #e9ecef;
}

.complaint_log_detail .log_status,
.complaint_log_detail .log_status_data {
    width: 100%;
    display: flex;
    align-items: center;
}

.log_status_data .attachment {
    flex-wrap: wrap;
}

.complaint_log_detail .log_status h5 {
    margin-bottom: 10px;
}

.complaint_log_detail .log_status_data h6 {
    margin: 3px 0;
}

.complaint_log_detail .log_status .log:nth-child(1),
.complaint_log_detail .log_status_data .log:nth-child(1) {
    width: 15%;
    text-align: left;
}

.complaint_log_detail .log_status .log:nth-child(2),
.complaint_log_detail .log_status_data .log:nth-child(2) {
    width: 45%;
    text-align: left;
}

.complaint_log_detail .log_status .log:nth-child(3),
.complaint_log_detail .log_status_data .log:nth-child(3) {
    width: 40%;
    text-align: center;
}

/* .complaint_log_detail .log_status_data .log:nth-child(3) {
    text-align: left;
} */

.complaint_log_detail .log_status_data .log .svg-inline--fa {
    font-size: 16px;
    cursor: pointer;
}

.complaint_log_detail .log_comment {
    margin-top: 15px;
}

.complaint_log_detail .comment_div {
    width: 100%;
    min-height: 50px;
    border-radius: var(--border_radius);
    border: 1px solid grey;
    margin-top: 5px;
    padding: 5px;
}

.complaint_detail_section .form_tabs .tab h4,
.raise_complaint_section .form_tabs .tab h4 {
    font-weight: bold;
    color: #fff !important;
}

.escalated_msg {
    text-align: center;
    margin-top: 15px;
}

.escalated_msg h6 {
    font-size: 15px;
}

.complaint_detail_section .form_tabs .personal_detail::after,
.complaint_detail_section .form_tabs .complaint_detail::after,
.raise_complaint_section .form_tabs .personal_detail::after,
.raise_complaint_section .form_tabs .complaint_detail::after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 25px;
    height: 100%;
    background-color: #fff;
    border: none;
    clip-path: polygon(100% 0, 39% 47%, 100% 100%);
}

.personal_detail_form,
.complaint_info_form {
    margin-top: 30px;
}

.personal_detail_form .input_field .form-check-input {
    margin-top: 5px;
}

.complaint_info_form .complaint_detail_textarea {
    max-height: 140px;
    overflow-y: scroll;
    background-color: #e9ecef;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    font-size: 14px;
    color: var(--primary_color);
}

.link {
    position: relative;
    cursor: pointer;
}

.link::after {
    position: absolute;
    content: "";
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 2px;
    border-bottom: 1px dotted var(--primary_color);
}

.mobile_verification .input_field .button {
    position: absolute;
    top: 0;
    right: 0;
    height: 35px;
}

.otp_verification_main {
    text-align: center;
}

.otp_verification {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.otp_verification input {
    width: 10%;
    text-align: center;
    font-weight: bold;
    border-radius: var(--border_radius);
    border: 1px solid var(--primary_color);
    margin: 0 10px;
}

.otp_verification input:focus {
    box-shadow: none;
}

.otp_verification_main span {
    color: var(--primary_color);
    cursor: pointer;
}

.otp_verification_main .button {
    margin-top: 15px;
}

.otp_verification_main .resend_otp_text {
    font-size: 14px;
    color: #3B4D59;
}

.raise_complaint_section .dashboard_form {
    width: 65%;
    max-height: 98%;
    overflow-y: auto;
    padding: 1% 4%;
    border-radius: var(--border_radius);
}

.raise_complaint_section .dashboard_form .button_group {
    justify-content: center;
    margin: 15px 0;
}

.raise_complaint_section .button_group .button:nth-child(2) {
    width: 150px;
}

.super_user_access_list_form {
    width: 90% !important;
    padding-bottom: 30px !important;
}

.super_user_access_list_form .close_complaint_model {
    text-align: right;
}

.super_user_access_list_form span {
    cursor: pointer !important;
}


.department_report .lookups_search_header {
    margin: 0;
}

.grpah_section {
    width: 100%;
    /* display: grid;
    place-items: center; */
    /* padding: 15px 0; */
    overflow-x: scroll;
}

.bar_graph_main::-webkit-scrollbar {
    height: 5px;
}

.bar_graph_main::-webkit-scrollbar-track {
    background-color: rgb(173, 173, 173);
}

.bar_graph_main::-webkit-scrollbar-thumb:horizontal {
    background-color: var(--primary_color);
    border-radius: 10px;
}

.bar_graph_main {
    width: 100%;
    overflow-x: scroll;
}

.grpah_section canvas {
    margin: 15px 0;
    cursor: pointer;
    max-height: 500px;
}

/*-------css for Notification tab-----*/
.notification_card {
    padding: 7px;
    border-radius: var(--border_radius);
    margin-bottom: 5px !important;
    position: relative;
    width: 100%;
}

.notification_card .content,
.notification_card .bell_icon {
    display: flex;
    align-items: center;
}

.notification_card .content span {
    border-bottom: 1px dotted var(--primary_color);
    cursor: pointer;
}

.notification_card .option_icon {
    display: flex;
    justify-content: end;
    align-items: center;
}

.notification_card .option_icon .svg-inline--fa {
    cursor: pointer;
    color: var(--primary_color);
}

.notification_card .notification_options {
    position: absolute;
    top: 10px;
    right: 2.5%;
    background-color: #fff;
    /* padding: 5px; */
    border-radius: var(--border_radius);
    border: 1px solid #DEDEDE;
    z-index: 1;
}

.notification_card .notification_options .tab {
    margin: 8px 5px;
    cursor: pointer;
}

/*-------css for rating-------------*/
.rating {
    display: flex;
    flex-direction: row-reverse;
    font-size: 1.5em;
    justify-content: space-around;
    padding: 0 0.2em;
    text-align: center;
    width: 25%;
}

.show_rating {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.show_rating .checked {
    color: #FFD700;
    margin-right: 5px;
}

.show_rating .unchecked {
    color: #FFD700;
    margin-right: 5px;
}

.rating input {
    display: none;
}

.rating label {
    position: relative;
    width: 1.1em;
    font-size: 2vw;
    color: #FFD700;
    cursor: pointer;
}

.rating label::before {
    content: "\2605";
    position: absolute;
    opacity: 0;
}

/* .rating label:hover:before,
.rating label:hover~label:before {
    opacity: 1 !important;
} */

.rating input:checked~label:before {
    opacity: 1;
}

/* .rating:hover input:checked~label:before {
    opacity: 0.4;
} */

/*---------css for dashboard main end---------*/

/*============CSS for Dashboard end==============*/

/***************  Public CSS Start  ***************/
.homepage_bg_color {
    background-color: #F4F4F4;
    position: relative;
    scrollbar-width: none;
}

::-webkit-scrollbar {
    width: 0;
}

.public_header_main {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
}

.ribbon_main {
    background-color: var(--ribbon_1st_theme);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--main_div_padding);
}

.ribbon_details {
    display: flex;
    align-items: center;
}

.ribbon_main a {
    color: #fff;
    font-weight: lighter;
    margin: 0 0.5rem;
}

.ribbon_main a h5 {
    font-size: 0.83rem;
    margin: 0 !important;
}

.ribbon_main .svg-inline--fa {
    color: #fff;
    height: 1rem;
    width: 1rem;
}

.ribbon_action_main {
    display: flex;
    text-align: center;
    align-items: center;
    /* overflow: hidden; */
}

.font_size {
    display: flex;
    text-align: center;
    align-items: center;
}

.font_size h4 {
    font-size: 0.83rem;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    padding: 0 0.3rem;
}

.toggel_buttom {
    margin: 0 0.3rem;
    display: flex;
}

.langauge_main {
    margin: 0 0.3rem;
    display: flex;
    align-items: center;
    height: 22px;
    overflow: hidden;
}

#google_translate_element {
    margin-top: 15px;
}

.goog-te-combo {
    /* padding: 5px; */
    width: 150px;
    font-family: 'Noto Sans', sans-serif !important;
    color: #6C757D;
    font-size: 10px !important;
    height: 20px !important;
    width: 110px !important;
}

.goog-logo-link {
    display: none;
}


.toggel_buttom .form-check-input {
    cursor: pointer;
}

.toggel_buttom .form-check-input:checked {
    background-color: #000;
    color: #000;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
}

.toggel_buttom .form-check-input:focus {
    outline: none;
    border: 1px solid #fff;
    box-shadow: none;
}

.header_section {
    display: flex;
    justify-content: space-between;
    background-color: #E1ECF3;
    align-items: center;
    padding: var(--main_div_padding);
}

.header_section img {
    height: 4rem;
}

.header_section button {
    border-radius: 5px;
    padding: 0.2rem 1rem;
    background-color: transparent;
    border: 1px solid #60676E;
    color: #60676E;
    font-weight: 600;
}

.header_section button:hover {
    border: 1px solid #fff;
    color: #000;
    background-color: #fff;
}


.navbar_main {
    display: flex;
    padding: var(--main_div_padding);
    background-color: #5E7082;
    justify-content: space-around;
    margin-bottom: 0.625rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.navbar_tab {
    width: 80%;
    display: flex;
    justify-content: space-around;
}

.navbar_main a {
    color: #fff;
    font-weight: bold;
}

.navbar_tab .active {
    padding-bottom: 0.1rem;
    border-bottom: 2px solid white;
}

.hero {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    position: relative;
}

.carousel-control-next,
.carousel-control-prev {
    width: 3%;
    align-items: end;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    width: 25px;
    height: 25px;
    margin-bottom: 10px;
}

.carousel-control-next {
    left: 4%;
}

.counter_main {
    height: 145px;
    display: flex;
    justify-content: space-around;
}

.counter_main .counter_bg_main {
    background-image: url("/public/Home/CounterBgImg1.png");
    background-repeat: no-repeat;
    width: 72vw;
    background-size: 100%;
    background-position: center;
}

.counter_main .counter_bg_main .count_main {
    display: grid;
    place-items: center;
}

.counter_main .counter_bg_main .count_main .count_child {
    display: flex;
    align-items: center;
}

.count_main .count_name_icon h5 {
    font-size: 0.8rem;
}

.count_main .count_name_icon h2 {
    font-size: 1.5rem;
}

.count_main .count_name_icon {
    font-size: 0.7rem;
    font-weight: 100;
    color: #60676E;
    text-align: center;
}

.count_main .count_name_icon .svg-inline--fa {
    height: 1.2rem;
    margin: 2px;
}

.count_child .yellow .svg-inline--fa {
    color: #FBD406;
}

.count_child .green .svg-inline--fa {
    color: #A6E335;
}

.count_child .skyblue .svg-inline--fa {
    color: #5ECBC4;
}

.count_child .blue .svg-inline--fa {
    color: #3B4D59;
}

.count_main .count_number h2 {
    font-size: 1.4rem;
    font-weight: bold;
    color: #60676E;
    margin-left: 0.5rem;
}

.track_complaint_main {
    background-color: #394D61;
    align-items: center;
    margin-bottom: 0.625rem;
}

.track_complaint_main .track_sub {
    padding: 3rem 1rem;
}

.track_complaint_main .track_text_main h3 {
    margin-bottom: 0;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
}

.track_complaint_main .track_button_main button {
    color: #394D61;
    padding: var(--main_div_padding);
    background-color: #fff;
    border: 1px solid #394D61;
    border-radius: 15px;
    font-size: 1.5rem;
    font-weight: bold;

}

.track_complaint_main .track_button_main button:hover {
    color: #fff;
    background-color: transparent;
    border: 1px solid #fff;
}

.heading_main {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.heading_main .map {
    text-align: right;
    margin-right: -15px;
}

.heading_main .map img {
    width: 70%;
    height: 100%;
    opacity: 0.6;
}

.main_heading_text {
    background-image: linear-gradient(to right, rgb(0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0));
    padding: 0.6rem 1rem;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main_heading_text h1 {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 40px;
    color: #fff;

}

/*-------Animated percentage------*/
.complaints_counter_section {
    padding: 1%;
}

.complaints_counter_section .complaints_counter_card {
    width: 80%;
    margin: auto;
    padding: 7% 0%;
    display: grid;
    place-items: center;
    background-image: linear-gradient(45deg, #fff, #E1ECF3);
    box-shadow: var(--box_shadow);
}

.percent {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    /* box-shadow: inset 0 0 50px #000;
    background: #222; */
    z-index: 1;
}

.percent .num {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: center;
}

.percent .num h2 {
    color: #777;
    font-weight: 700;
    font-size: 14px;
    transition: 0.5s;
    margin-bottom: 0;
}

.card:hover .percent .num h2 {
    color: #000;
    font-size: 60px;
}

.percent .num h2 span {
    color: #777;
    font-size: 24px;
    transition: 0.5s;
}

.card:hover .percent .num h2 span {
    color: #fff;
}

.text {
    position: relative;
    color: #777;
    margin-top: 20px;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: 0.5s;
}

.card:hover .text {
    color: #fff;
}

.percent svg {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    z-index: 1;
    background-image: linear-gradient(45deg, #fff, #E1ECF3);
    box-shadow: var(--box_shadow);
}

.percent svg circle {
    width: 100%;
    height: 100%;
    fill: none;
    stroke-width: 10;
    stroke-linecap: round;
    transform: translate(5px, 5px);
}

.percent svg circle:nth-child(1) {
    stroke: none;
}

.percent svg circle:nth-child(2) {
    stroke-dasharray: 440;
    stroke-dashoffset: 440;
}

/* svg circle:nth-child(2) {
    stroke-dashoffset: calc(440 - (440 * 50) / 100);
    stroke: #00ff43;
} */

/*-------Animated percentage------*/

.site_visitor_box {
    border: 1px solid #fff;
    background-color: transparent;
    margin-top: 1rem;
    text-align: center;
    width: 17rem;
    border-radius: 10px;
}

.site_visitor_box .site_visitor_details {
    padding: 0.5rem 0;
}

.site_visitor_box h2,
.site_visitor_box h3 {
    color: #fff;
    margin: 0;
    padding: 0;
}

.site_visitor_box h2 {
    font-size: 1.6rem;
}

.site_visitor_box h3 {
    font-size: 1rem;
    padding-bottom: 0.3rem;
}

.about_border {
    border-top: 1px solid #5E7082;
    border-bottom: 1px solid #5E7082;
    margin: 0.625rem 0;
}

.about_section_bg {
    background-image: linear-gradient(45deg, white, #E1ECF3, #E1ECF3, #E1ECF3);
    margin: 0.625rem 0;
}

.about_section_bg .about_img_box,
.about_section_bg .about_img_box img {
    width: 100%;
}

.about_content {
    display: grid;
    place-content: center;
    padding: 2rem 0.625rem;
}

.about_content h2 {
    color: var(--theme_blue);
    font-weight: bold;
    font-size: 3rem;
    margin-bottom: 0.5rem;
}

.about_content .paragraph {
    font-weight: bold;
    width: 90%;
    margin-bottom: 2rem;
    text-align: justify;
}

.about_content button {
    color: var(--theme_blue);
    border: 1px solid var(--theme_blue);
    padding: 0.3rem 2rem;
    font-size: 1.5rem;
    font-weight: bold;
    border-radius: 50px;
    background-color: transparent;
}

.about_content .underline_thik {
    background-color: #A6CAE2;
    width: 80%;
    height: 5px;
}

.about_content .underline_thin {
    height: 2px;
    width: 80%;
    background-color: #fff;
    margin-bottom: 1.5rem;
}

.admin_main {
    margin: 0.625rem 0;
    background-color: #fff;
    box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px;
}

.admin_main .admin_heading {
    padding-top: 1rem;
}

.admin_main .admin_heading h3 {
    font-size: 1rem;
    font-weight: bold;
    margin: 0 0.625rem;
}

.admin_main .heading_line {
    margin: 0.625rem;
}

.admin_main .heading_line .underline_thik {
    background-color: #413838;
    width: 12rem;
    height: 5px;
}

.admin_main .heading_line .underline_thin {
    height: 2px;
    width: 12rem;
    background-color: #a1a1a1;
    margin-bottom: 1.5rem;
}

.admin_main .container-fluid {
    margin: auto;
    padding-bottom: 1rem;
}

.card_width {
    padding: 0 1.2rem;
    margin-bottom: 0.625rem;
}

.admin_card_main .img_box_main {
    background-color: #fff;
    text-align: center;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    padding: 3rem 0;
}

.admin_card_main .img_box_main .img_box_outer {
    display: grid;
    place-content: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.admin_card_main .img_box_main .img_box_outer .img_box {
    width: 150px;
    height: 150px;
    border-radius: 100px;
    border: 1px solid grey;
    overflow: hidden;
}

.admin_card_main .img_box_main .img_box_outer .img_box img {
    width: 100%;
}

.admin_card_main .img_box_main .admin_name h4 {
    font-size: 1rem;
    font-weight: bold;
}

.content_bg {
    background-color: #5E7082;
    display: grid;
    place-content: center;
}

.admin_content_main {
    padding: 2rem 1rem;
}

.admin_content_main h4 {
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
}

.admin_content_main p {
    font-size: 1rem;
    padding-top: 1rem;
    text-align: justify;
    color: #fff;
    margin: 0;
}

.footer_main {
    background-color: #5E7082;
    margin-top: 0.625rem;
}

.footer_main .heading_line {
    padding: 3rem 0;
}

.footer_main .heading_line .underline_thik {
    background-color: #394D61;
    width: 95%;
    height: 5px;
    margin: auto;
}

.footer_main .heading_line .underline_thin {
    height: 2px;
    width: 95%;
    background-color: #6E8194;
    margin-bottom: 1.5rem;
    margin: auto;
}

.footer_copyright {
    background-color: #394D61;
}

.footer_copyright .left {
    text-align: left;
}

.footer_copyright .right {
    text-align: right;
}

.footer_copyright p {
    margin: 0;
    font-size: 0.7rem;
    color: #fff;
    padding: 0.6rem 2rem;
    font-weight: bold;
}

.footer_link_main a {
    text-decoration: none;
}

.footer_link_main ul li {
    display: flex;
    align-items: center;
}

.footer_link_main ul .footer_heading h3 {
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
}

.footer_link_main ul .footer_link p {
    font-size: 1rem;
    color: #fff;
    padding-top: 0.5rem;
    font-weight: normal;
    font-size: 0.813rem;
}

.footer_link_main ul .footer_link .svg-inline--fa {
    color: #fff;
    padding-top: 0.5rem;
    margin-right: 0.6rem;
    width: 1rem;
}

.footer_link_main ul .footer_heading img {
    height: 70px;
}

.social_media_heading {
    padding: 1rem 0;
    text-align: center;
}

.social_media_heading h3 {
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
}

.social_media_main {
    width: 100%;
    padding: 0.65rem;
}

.social_media_main .social_media_wrapper {
    width: 60%;
    margin: auto;
    display: flex;
    justify-content: space-around;
}

.social_media_main .social_media_wrapper .icon_main {
    width: 3.1rem;
    height: 3.1rem;
    background-color: #394D61;
    border-radius: 10px;
    box-shadow: rgb(0, 0, 0) 1px 1px 3px 0px inset;
    display: grid;
    place-content: center;
}

.social_media_main .social_media_wrapper .icon_main .svg-inline--fa {
    color: #fff;
    height: 1.5rem;
}

.imp_link_main {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin: 0.625rem 0;
}

.imp_link_main .imp_link_inner_main {
    background-color: #E1ECF3;
    margin: 0.625rem 0;
    padding: 10px 0;
}

.imp_link_main .imp_link_inner_main .card_main_div {
    margin: 0.625rem auto;
    /* width: 80%; */
}

.imp_link_main .imp_link_inner_main .card_main_div img {
    width: 100%;
    height: 100%;
    filter: grayscale();
}

.card_main_div .wraper {
    width: 70%;
    height: 180px;
    margin: auto;
    overflow: hidden;
    position: relative;
}

.card_main_div .wraper .first_slider {
    width: 80%;
    margin: auto 10%;
    height: calc(60px * 5);
    animation: verticle_bottom 20s infinite linear;
    position: absolute;
    top: calc(-60px * 5);
}

@keyframes verticle_bottom {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(calc(80px * 5));
    }
}

.card_main_div .wraper .second_slider {
    width: 80%;
    margin: auto 10%;
    height: calc(60px * 5);
    animation: verticle_top 20s infinite linear;
    position: absolute;
    bottom: calc(-60px * 4.5);
}

@keyframes verticle_top {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(calc(-80px * 5));
    }
}

.card_main_div .wraper .first_slider:hover,
.card_main_div .wraper .second_slider:hover {
    animation-play-state: paused;
}

.imp_link_main .imp_link_inner_main .card_main_div .imp_link_div:hover img {
    filter: grayscale(0);
}

.imp_link_main .imp_link_inner_main .card_main_div .imp_link_div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 60px;
    border-radius: 50px;
    background-color: #EBF2F6;
    padding: 5px;
    margin: 30px 0;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.imp_link_div .title {
    text-align: center;
}

.imp_link_main .imp_link_inner_main .card_main_div .imp_link_div a {
    text-decoration: none;
    color: #000;
    font-size: 1rem;
    font-weight: 700;
}

.imp_link_heading {
    padding-top: 1rem;
}

.imp_link_heading h4 {
    font-size: 1rem;
    font-weight: bold;
    margin: 0 0.625rem;
}

.imp_link_heading_line {
    margin: 0.625rem;
}

.imp_link_heading_line .underline_thik {
    background-color: #A6CAE2;
    width: 12rem;
    height: 5px;
}

.imp_link_heading_line .underline_thin {
    height: 2px;
    width: 12rem;
    background-color: #fff;
    margin-bottom: 1.5rem;
}


.slick-initialized .slick-slide {
    text-align: center;
}

.slick-initialized .slick-slide .card_main {
    width: 98% !important;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    margin-top: 1rem !important;
    margin-bottom: 2rem !important;
}

.slick-initialized .slick-slide .img_box {
    width: 100%;
    display: grid;
    place-content: center;
    justify-items: center;
}

.slick-initialized .slick-slide .img_box .img_wrapper {
    width: 80%;
    height: auto;
}

.slick-initialized .slick-slide .img_box .img_wrapper img {
    width: 100%;
}

.slick-initialized .slick-slide h4 {
    font-size: 0.8rem;
    color: #000;
    font-weight: bold;
    padding: 1rem 0;
}

.gallary_row {
    padding-bottom: 0.625rem;
}

.gallary_main {
    padding: 1rem 0;
}

.gallary_section {
    box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px;
    background-color: #394D61;
    margin: 0.625rem 0;
    position: relative;
}

.gallary_section .container-fluid .row .gallary_box {
    height: 40vh;
    border: 0.3px solid#707070;
    border-left: none;
    position: relative;
}

.gallary_section .container-fluid .row .gallary_box:nth-child(4),
.gallary_section .container-fluid .row .gallary_box:nth-child(5),
.gallary_section .container-fluid .row .gallary_box:nth-child(6),
.gallary_section .container-fluid .row .gallary_box:nth-child(7),
.gallary_section .container-fluid .row .gallary_box:nth-child(8),
.gallary_section .container-fluid .row .gallary_box:nth-child(9) {
    border-top: none;
}

.gallary_heading h3 {
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    margin-bottom: 0.625rem;
    padding-top: 1rem;
}

.gallary_section .underline_thik {
    background-color: #122C46;
    width: 50%;
    height: 5px;
}

.gallary_section .underline_thin {
    height: 2px;
    width: 50%;
    background-color: #A1A1A1;
}

.gallary_section ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-around;
    color: #fff;
}

.gallary_section ul li h3 {
    font-weight: bold;
    font-size: 1rem;
    padding-top: 1rem;
    cursor: pointer;
}

.gallary_section ul .all,
.gallary_section ul .transport,
.gallary_section ul .medical,
.gallary_section ul .corporation {
    border-bottom: 1px solid #fff;
    padding-bottom: 0.1rem;
}


.gallary_box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.gallary_img_box {
    width: 90%;
    height: 80%;
    align-content: center;
}


.gallary_img_box img {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

/* The Modal (background) */
.modal_box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.87);
    padding: 3rem 0;
    z-index: 11;
}

.modal_box img {
    width: 100%;
    height: auto;
}

/* Modal Content (image) */
.modal_box .modal-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    margin-top: 3rem;
}


/* Add Animation */
.modal_box .modal-content {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
    from {
        -webkit-transform: scale(0)
    }

    to {
        -webkit-transform: scale(1)
    }
}

@keyframes zoom {
    from {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}

/* The Close Button */
.modal_box .close {
    position: absolute;
    top: 5px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.modal_box .close:hover,
.modal_box.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
    .modal .modal-content {
        width: 100%;
    }
}

.gallary_box {
    opacity: 0.3;
}

.gallary_box:hover {
    opacity: 1;
}

.gallary_box:hover span {
    position: absolute;
    background-color: #fff;
    transition: 2;
}

.gallary_box:hover span:nth-child(9) {
    width: 64px;
    height: 2px;
    top: 0;
    left: 0;
    transition: 2;
}

.gallary_box:hover span:nth-child(2) {
    width: 2px;
    height: 64px;
    top: 0;
    left: 0;
    transition: 2;
}

.gallary_box:hover span:nth-child(3) {
    width: 64px;
    height: 2px;
    top: 0;
    right: 0;
    transition: 2;
}

.gallary_box:hover span:nth-child(4) {
    width: 2px;
    height: 64px;
    top: 0;
    right: 0;
    transition: 2;
}

.gallary_box:hover span:nth-child(5) {
    width: 64px;
    height: 2px;
    bottom: 0;
    right: 0;
    transition: 2;
}

.gallary_box:hover span:nth-child(6) {
    width: 2px;
    height: 64px;
    bottom: 0;
    right: 0;
    transition: 2;
}

.gallary_box:hover span:nth-child(7) {
    width: 64px;
    height: 2px;
    bottom: 0;
    left: 0;
    transition: 2;
}

.gallary_box:hover span:nth-child(8) {
    width: 2px;
    height: 64px;
    bottom: 0;
    left: 0;
    transition: 2;
}


/*////////  About Page CSS Start  ////////*/

.about_page_main {
    margin: 0.625rem 0;
}

/* .app_flow_main {
    height: 145px;
    display: flex;
    justify-content: space-around;
    margin-bottom: 0.625rem;
}

.app_flow_main .app_flow_bg_main {
    background-image: url("/public/AboutUs/aboutbg.png");
    background-repeat: no-repeat;
    width: 95vw;
    background-size: 100%;
    background-position: center;
}

.app_flow_main .app_flow_bg_main .app_flow_col {
    display: grid;
    place-items: center;
}

.app_flow_main .app_flow_bg_main .app_flow_col .app_flow_col {
    display: flex;
    align-items: center;
    text-align: center;
}

.app_flow_col .count_name_icon {
    font-size: 0.7rem;
    font-weight: 100;
    color: #60676E;
    text-align: center;
    text-decoration: underline;
}

.app_flow_col .app_flow_name_icon .svg-inline--fa {
    height: 4rem;
}

.app_flow_col .yellow .svg-inline--fa {
    color: #FBD406;
}

.app_flow_col .green .svg-inline--fa {
    color: #A6E335;
}

.app_flow_col .skyblue .svg-inline--fa {
    color: #5ECBC4;
}

.app_flow_col .blue .svg-inline--fa {
    color: #3B4D59;
}

.app_flow_col .app_flow_name_icon h5 {
    font-size: 12px;
    margin-top: 0.3rem;
    font-weight: 800;
    text-transform: capitalize;

} */
.app_flow_main {
    width: 95%;
    margin: auto;
}

.app_flow_main .app_flow_bg {
    background-image: url("/public/AboutUs/appflowfive.png");
    height: 120px;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    width: 100%;
}

.app_flow_main .app_flow_bg .app_flow_row {
    display: flex;
    align-items: center;
    width: 98%;
    margin: auto;
}

.app_flow_main .app_flow_bg .app_flow_row .flow_col {
    width: 20%;
    text-align: center;
    padding: 15px 0;
}

.app_flow_main .app_flow_bg .app_flow_row .flow_col img {
    height: 3rem;
    margin: 0.2rem auto;
}

.app_flow_main .app_flow_bg .app_flow_row .flow_col h5 {
    font-size: 0.8rem;
    font-weight: 700;
    text-transform: capitalize;
    padding: 0 35px;
}

.about_page_content_main {
    margin: 0.625rem 0;
}

.about_page_content_main .about_page_content_inner_main {
    background-color: #E1ECF3;
    margin: 0.625rem 0;
    padding: 10px 0;
}

.about_page_content_main .about_page_content_inner_main .card_main_div {
    margin: 0.625rem auto;
    width: 80%;
}

.about_page_content_heading {
    padding-top: 1rem;
}

.about_page_content_heading h4 {
    font-size: 1rem;
    font-weight: bold;
    margin: 0 0.625rem;
}

.about_page_content_heading_line {
    margin: 0.625rem;
}

.about_page_content_heading_line .underline_thik {
    background-color: #A6CAE2;
    width: 12rem;
    height: 5px;
}

.about_page_content_heading_line .underline_thin {
    height: 2px;
    width: 12rem;
    background-color: #fff;
    margin-bottom: 1.5rem;
}

.about_page_content_main .about_page_content_inner_main .about_page_content_box {
    width: 99%;
    margin: auto;
    color: #000;
}

.about_pg_img_box {
    display: grid;
    place-content: center;
}

.about_pg_img_box img {
    width: 100%;
}

.about_page_content_main .about_page_content_inner_main .about_page_content_box .content_main {
    padding: 1%;
    display: grid;
    place-content: center;
}


.about_page_content_main .about_page_content_inner_main .about_page_content_box h3 {
    font-weight: bold;
    margin-bottom: 5px;
}

.about_page_content_main .about_page_content_inner_main .about_page_content_box p {
    margin-bottom: 5px;
    font-weight: 500;
    text-align: justify;
}

/*/////////  About Page CSS End  /////////*/

/*/////////  Department Page CSS Start  /////////*/

.department_main {
    margin: 0.625rem 0;
    background-color: #fff;
    box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px;
}

.department_main .department_heading {
    padding-top: 1rem;
}

.department_main .department_heading h3 {
    font-size: 1rem;
    font-weight: bold;
    margin: 0 0.625rem;
}

.department_main .heading_line {
    margin: 0.625rem;
}

.department_main .heading_line .underline_thik {
    background-color: #413838;
    width: 12rem;
    height: 5px;
}

.department_main .heading_line .underline_thin {
    height: 2px;
    width: 12rem;
    background-color: #a1a1a1;
    margin-bottom: 1.5rem;
}

.department_main .department_table_heading {
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    font-weight: 800;
}

.department_main .department_table_heading .first_heading,
.department_main .department_table_data .first_data {
    width: 15%;
}

.department_main .department_table_heading .second_heading {
    width: 40%;
}

.department_main .department_table_data {
    color: #000;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #707070;
}

.department_main .department_table_data .second_data {
    width: 40%;
}

.department_main .department_table_data .second_data .row_subdepartment {
    width: 100%;
}

.department_main .department_table_data .second_data .row_subdepartment .col_subdepartment {
    width: 100%;
    margin-bottom: 0.625rem;
}



.department_table_main {
    width: 98%;
}

.department_table_main .department_list_not_available {
    height: 30vh;
    padding-top: 5%;
    text-align: center;
}

/*//////////  Department Page CSS End  //////////*/

/*/////////  FAQ Page CSS Start  /////////*/
.faq_main {
    margin: 0.625rem;
}

.faq_heading_main {
    background-color: #fff;
    margin: 0.625rem 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.faq_heading_main h3 {
    padding: 1rem;
    text-transform: uppercase;
    font-weight: 800;
    margin-left: 1rem;
}

.faq_main .que_ans {
    display: flex;
}

.que_ans_text_box {
    background-color: #fff;
    margin: 0.625rem 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.que_main {
    display: flex;
    align-items: center;
}

.que {
    width: 84%;
}

.que h3 {
    font-weight: 800;
}

.que_mark_icon {
    margin-left: 1rem;
}

.que_drop_icon,
.que_mark_icon {
    width: 8%;
    padding: 1rem;
}

.que_drop_icon {
    cursor: pointer;
}

.que_drop_icon img {
    height: 10px;
}

.ans_main {
    display: flex;
    align-items: center;
}

.ans_main .ans_sapace {
    width: 8%;
}

.ans_text_box {
    padding: 1rem;
    width: 84%;
}

.ans_text_box .answer {
    display: flex;
    align-items: center;
    gap: 12px;
}

.ans_text_box p {
    text-align: justify;
    font-weight: 500;
}

/*//////////  FAQ Page CSS End  //////////*/

/*/////////  Contact us Page CSS Start  /////////*/
.contact_main {
    margin: 0.625rem 0;
    background-color: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.contact_main .container {
    padding: 0.625rem 0;
    overflow: hidden;
}

.contact_main .container .row .map_emabed {
    background-color: #fff;
}

.contact_main .container .row .map_emabed .embed_box {
    padding: 2rem 0;
    width: 100%;
}

.contact_main .container .row .location_content_box {
    display: grid;
    place-content: center;
    color: #fff;
}

.contact_details_box {
    padding: 1rem;
}

.contact_main .container .row .location_content_box a {
    color: #fff;
}

.contact_main .container .row .location_content_box h3 {
    margin-top: 0.825rem;
}

.contact_main .container .row .location_content_box p {
    margin-top: 0.622rem;
}

/*//////////  Contact us Page CSS End  //////////*/

/*=========Public Modal CSS Start===============*/
.public_form_section {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    background-color: rgba(0, 0, 0, 0.507);
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
}

.public_form_section .form_main {
    width: 60%;
    background-color: #F4F4F4;
    border-radius: 10px;
}

.form_main .form_header {
    width: 90%;
    margin: auto;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 5px solid #E1DFDF;
}

.form_main .form_header h6 {
    font-weight: bold;
}

.form_main .form_header .svg-inline--fa {
    font-size: 24px;
    cursor: pointer;
}

.form_main .form {
    width: 85%;
    text-align: center;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 15px;
}

.form_main .form .mobile_verification {
    width: 60%;
    margin: auto;
    position: relative;
    margin-top: 25px;
    margin-bottom: 15px;
}

.form input,
.form .form-select {
    border: 1px solid #3B4D59;
    box-shadow: var(--input_box_shadow);
}

.form input::placeholder {
    color: #3B4D59 !important;
}

.form .form-select {
    color: #3B4D59;
}

.form textarea {
    border: 1px solid #3B4D59;
    box-shadow: var(--input_box_shadow);
}

.form textarea::placeholder {
    color: #3B4D59 !important;
}

.form .mobile_verification button {
    position: absolute;
    top: 0;
    right: 0;
    height: 35px;
    background-color: #3B4D59;
    color: #fff;
    border: none;
    padding: 0 10px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-weight: bold;
}

.form .otp_verification_main input {
    width: 7%;
}

.form .otp_verification_main h6 {
    font-weight: bold;
}

.mobile_verification .otp_verification input:focus {
    outline: none;
}

.response_form h6 {
    font-weight: bold;
    margin: 10px 0;
}

.form .otp_verification_main span {
    color: #3B4D59;
}

.form p,
.raise_complaint_section .complaint_detail_form p {
    font-size: 11px;
    margin-top: 5px;
    color: red;
    font-weight: bold;
}

.raise_complaint_section .complaint_detail_form textarea {
    min-height: 90px;
}

.form .otp_verification_main button,
.form .form_button_group button,
.response_form button {
    background-color: #3B4D59;
    color: #fff;
    border: none;
    padding: 5px 30px;
    border-radius: 5px;
    font-weight: bold;
    margin: 15px 0;
    font-size: 13px;
    box-shadow: var(--box_shadow);
}

.form .form_button_group button {
    padding: 5px 15px;
    margin-right: 10px;
}

.form .form_button_group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.raise_complaint_form .raise_complaint_tabs {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.raise_complaint_form .raise_complaint_tabs .tab {
    background-color: #3B4D59;
    padding: 5px 25px;
    border-radius: var(--border_radius);
    position: relative;
    cursor: pointer;
    margin-right: 15px;
}

.raise_complaint_form .raise_complaint_tabs .tab h6 {
    color: #fff;
}

.raise_complaint_form .raise_complaint_tabs .personal_detail::after,
.raise_complaint_form .raise_complaint_tabs .complaint_detail::after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 25px;
    height: 100%;
    background-color: #fff;
    border: none;
    clip-path: polygon(100% 0, 39% 47%, 100% 100%);
}

.public_personal_detail_form,
.public_complaint_detail_form,
.raise_complaint_section .personal_detail_form,
.raise_complaint_section .complaint_detail_form {
    margin-top: 25px;
}

.public_personal_detail_form .input_field {
    margin-bottom: 25px;
}

.public_personal_detail_form .input_field label h6,
.public_complaint_detail_form .input_field label h6 {
    font-size: 14px;
    color: #212529;
}

.public_complaint_detail_form .attachement,
.raise_complaint_section .complaint_detail_form .attachement_field {
    position: relative;
}

.public_complaint_detail_form .attachement h6,
.raise_complaint_section .complaint_detail_form .attachement h6,
.raise_complaint_section .complaint_detail_form h6 {
    font-size: 11px;
    font-weight: bold;
    margin-top: 3px;
    color: var(--primary_color);
}

.public_complaint_detail_form .attachement .preview,
.raise_complaint_section .complaint_detail_form .attachement_field .preview {
    position: absolute;
    top: 8%;
    right: -5%;
    cursor: pointer;
}

.raise_complaint_section .complaint_detail_form .attachement_field .preview {
    color: var(--primary_color);
}

/* ========== Track Complaint Css Start========== */
.complaint_Login_main {
    display: flex;
    flex-direction: column;
}

.complaint_Login_main input {
    outline: none;
    border-radius: 5px;
    border: 1px solid #3B4D59;
    margin: 0.625rem auto;
    width: 40%;
    color: #3B4D59;
    padding: 0.625rem 1rem;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.complaint_Login_main input::placeholder {
    color: #3B4D59 !important;
    font-size: 0.7rem;
}

.complaint_login_button {
    display: flex;
    align-items: center;
    margin: 0.625rem auto;
    justify-content: center;
}

.complaint_login_button button {
    font-size: 0.9rem;
    font-weight: 600;
    color: #fff;
    border: none;
    background-color: #3B4D59;
    padding: 0.2rem 1rem;
    border-radius: 5px;
    margin-left: 0.625rem;
}

/* ========== Track Complaint Status Css Start========== */
.track_complaint_section .form_main {
    max-height: 90vh;
    overflow: auto;
}

.complaint_status_main_data {
    margin-top: 0.625rem;
}

.complaint_status_main_data h3 {
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 0.625rem;
}

.complaint_status_main .status {
    text-transform: capitalize;
}

.complaint_status_main_data .status_box {
    width: 90%;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    margin: auto;
    background-color: #fff;
    border-radius: 15px;
    position: relative;
}

.complaint_status_main_data .status_box h3 {
    padding: 0.5rem;
    font-size: 0.8rem;
    text-align: justify;
    padding: 1rem;
}

.Public_complaint_status_attachment {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
}

.Public_complaint_status_attachment h4 {
    font-weight: 700;
    font-size: 13px;
}

.Public_complaint_status_attachment .svg-inline--fa {
    margin-left: 10px;
    cursor: pointer;
}

.complaint_status_main {
    margin-top: 0.625rem;
}

.complaint_status_main h3 {
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 0.625rem;
}

.status_box {
    width: 95%;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    margin: auto;
    background-color: #fff;
    border-radius: 30px;
    position: relative;
}

.complaint_status_button {
    padding: 1rem;
}

.complaint_status_button button {
    font-size: 0.9rem;
    font-weight: 600;
    color: #fff;
    border: none;
    background-color: #3B4D59;
    padding: 0.2rem 1rem;
    border-radius: 5px;
    margin-bottom: 0.625rem;
}

.status_main_box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    overflow-x: auto;
    padding: 1.2rem 0;
}

.status_box .center_line {
    height: 5px;
    background-color: #000;
    position: absolute;
    z-index: 12;
    width: 85%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.status_main_box .status_name {
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* margin: 0 0.2rem; */
    z-index: 14;

}

.status_main_box .complaint_status {
    color: #fff;
    background-color: #A6E335 !important;
}



.status_main_box .status_name h4 {
    font-weight: 700;
    font-size: 0.8rem;
    padding: 0.525rem 1rem;
    margin-bottom: 0;
}


/*=========Public Modal CSS End===============*/
/*-----Public Table css start-----*/
.public_table_section {
    width: 100vw;
    height: auto;
    background-image: url("/public/Home/background.png");
}

.public_table_section .outer_wrapper {
    width: 100%;
    height: auto;
    padding: 2%;
    background-color: rgba(0, 0, 0, 0.589);
}

.public_table_section .outer_wrapper .wrapper {
    width: 85%;
    min-height: 50vh;
    max-height: 70vh;
    overflow: auto;
    margin: auto;
    background-color: #fff;
    padding: 0px 10px 10px 10px;
    border-radius: var(--border_radius);
}

.public_table_section .public_table_main {
    width: 100%;
    overflow: auto;
}

.public_table_section .public_table_main table thead tr {
    background-color: #3B4D59 !important;
}

.public_table_section .table_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 2;
    padding: 5px 0;
}

.public_table_section .table_header button {
    background-color: #3B4D59;
    color: #fff;
    border: none;
    padding: 3px 10px;
    border-radius: var(--border_radius);
    font-size: 14px;
}

/****************  Public CSS End  ****************/

/*----pagination css---------*/
.pagination {
    /* margin: 5px 0 0 0; */
    display: flex;
    list-style: none;
    outline: none;
    margin-bottom: 0;
}

.pagination>.active>a {
    background-color: var(--primary_color);
    border-color: var(--primary_color);
    color: #fff;
}

.pagination>li>a {
    border: 1px solid var(--primary_color);
    padding: 2px 10px;
    outline: none;
    cursor: pointer;
    color: var(--primary_color) !important;
    font-size: 12px;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    background-color: var(--primary_color);
    border-color: var(--primary_color);
    outline: none;
    color: #fff !important;
}

.pagination>li>a,
.pagination>li>span {
    color: var(--primary_color);
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-radius: unset
}

.pagination_div,
.grap_pagination_div {
    /* text-align: right;
    margin-right: 0;
    margin-left: auto; */
    position: absolute;
    bottom: -1%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.grap_pagination_div {
    /* min-width: 30%; */
    background-color: #fff;
    bottom: -2%;
    margin: auto;
    display: grid;
    place-items: center;
}

.graph_pagination_div {
    display: flex;
    text-align: right;
}

/*-------CSS for Print---------*/
@media print {
    .grpah_section {
        width: auto !important;
        height: auto;
        overflow: visible;
    }

    .bar_graph_main {
        width: auto !important;
        height: auto;
        overflow: visible;
        transform: rotate(90deg);
        margin-top: 20% !important;
    }

    .bar_graph_main div {
        min-width: auto !important;
        height: auto;
        overflow: visible;
    }

    .grpah_section canvas {
        height: auto !important;
        overflow: visible;
    }

    .deaprtment_graph {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        height: 100vh !important;
        width: auto !important;
    }

}

/*===============css for export to excel modal=============*/
.exportToExcel_modal_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    z-index: 2;
}
.exportToExcel_modal_wrapper .dashboard_form {
    width: 50%;
    max-height: 98%;
    overflow-y: auto;
    padding: 2% 2%;
    border-radius: var(--border_radius);
    border: 2px solid var(--primary_color);
}
.exportToExcel_modal_wrapper .dashboard_form label {
    font-weight: 500;
}